import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout/layout'
import { Container, StyledButton, Subline, Headline } from '../components/layout/layoutComponents'
import { typo, colors } from '../components/utils'

const Hero = styled.div`
  overflow-x: hidden;
  padding: 0 35px;
  margin: 0 auto;
  width: 100%;
  height: 435px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #558A50;
  @media (min-width: 460px) {
    flex-direction: row-reverse;
  }
  @media (min-width: 720px) {
    height: 635px;
  }
  @media (min-width: 1024px) {
    height: 835px;


  }
`

const HeroHeader = styled.h1`
  max-width: 552px;
  font-size: 32px;
  line-height: 46px;
  color: #fff;
  letter-spacing: 6px;
  text-transform: uppercase;
  @media (min-width: 720px) {
    margin-left: 35px;
    font-size: 68px;
    line-height: 78px;
  }
`
const HeroImage = styled.img`
  max-height: 180px;
  max-width: 240px;
  margin: 0;
  @media (min-width: 720px) {
    max-height: 320px;
    max-width: 460px;
  }
  @media (min-width: 1024px) {
    max-height: 440px;
    max-width: 550px;
  }
`
const StyledArticle = styled.article`
  max-width: 1140px;
  margin: 0 auto;
  padding: 100px 35px;
  font-size: 20px;
  h2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 59px;
    color: ${colors.default};
    text-transform: uppercase;
    margin: 0;
    @media(min-width: 690px) {
      font-size: ${typo.fontheadline};
      line-height: ${typo.lineheadline};
    }
  }
  h3 strong {
    font-size: 24px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1.8px;
    color: ${colors.orange};
    text-transform: uppercase;
    margin-bottom: 30px;
    font-weight: 500;
    @media (min-width: 425px) {
      font-size: 26px;
      line-height: 30px;
    }
    @media (min-width: 720px) {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 59px;
    }
    @media (min-width: 1140) {
      font-size: ${typo.fontsubline};
      line-height: ${typo.linesubline};
      letter-spacing: ${typo.spacinglarge};
    }
  }
`
const ArticleText = styled.div`
  margin-top: 66px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.2px;
    color: ${colors.default};
    margin: 0;
    margin-bottom: 30px;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-size: 16px;
    font-weight: ${typo.regular};
    line-height: 22px;
    color: ${colors.default};
    list-style: none;
    li {
      position: relative;
      margin-bottom: 15px;
      &:before{
        content: '';
        position: absolute;
        top: 8px;
        left: -39px;
        height: 13px;
        width: 13px;
        background-color: ${colors.orange};
      }
    }  
  }
`

export default function ColumnsTemplate ({ pageContext: { data }}) {
  const content = data.article
  return (
  <Layout>
    <Hero>
        <HeroImage src={data.imageUx[0].url} placeholder={data.title} />
        <HeroHeader>{data.title}</HeroHeader>
    </Hero>
    <Container>
      <StyledArticle>
        <Headline style={{fontWeight: `600`}}>{data.textHeader}</Headline>
        <Subline>{data.textSubheader}</Subline>
        <ArticleText dangerouslySetInnerHTML={{ __html: content }} />
        <StyledButton style={{margin: `auto`, marginRight: `10%`}}>
          <Link to={data.linkTo}>Contact</Link>
        </StyledButton>
      </StyledArticle>
    </Container>
  </Layout>
)}